import React, { ReactElement } from "react";

import styles from "./styles.module.scss";
import { useTranslation } from "@hooks/useTranslation";
import ScrollToTop from "@components/shared/scrollToTop";

export default function Lawsuit(): ReactElement {
    const { translate } = useTranslation();
    return (
        <section className={`${styles["mainContainer"]} px-5 lg:px-0`}>
            <div className="py-16">
                <h2>{translate("LAWSUIT_COST_TITLE")}</h2>
                <p className="mx-auto">
                    {translate("LAWSUIT_COST_DESCRIPTION")}
                </p>
                <ScrollToTop
                    colors={{
                        bg: "#d21900",
                        text: "white",
                    }}
                    className="rounded"
                >
                    {translate("GET_CASE_REVIEW")}
                </ScrollToTop>
            </div>
        </section>
    );
}
